import React from "react"
import { Link, graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import FeaturedContentSlider from "../content/featuredContent/FeaturedContentSlider"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../content/preview/ContentPreview"
import indexColumns from "../utils/indexColumns"
import Text from "../content/texts/Text"
import { Player } from 'video-react'
import Flickity from "react-flickity-component"

import { StaticImage } from "gatsby-plugin-image"

import { invertHeader, resetHeader } from "../actions"
import { connect } from "react-redux"

const flickityOptions = {
  wrapAround: true,
  pageDots: true,
  draggable: true,
  contain: true,
  prevNextButtons: false,
  autoPlay: 5000,
  pauseAutoPlayOnHover: false
}

class LandingPage extends React.Component {
  constructor(props) {
    super(props)

    this.maxColumn = 6

    // create class instance for column number, delay, etc
    this.indexColumns = new indexColumns(0, this.maxColumn)

  }

  componentDidMount() {
    this.props.invertHeader()
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 25)
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  layout = index => {
    let className = []

    // 1st item
    if (this.indexColumns.columnNumber === 1) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_fill)
    }

    // first element compact element, set "compact_fill" layout
    if (this.indexColumns.columnNumber === 2) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.comact)

      // 3rd is fullwith
    } else if (
      this.indexColumns.columnNumber === 3 ||
      this.indexColumns.columnNumber === this.maxColumn
    ) {
      className.push(`col-12`)
      className.push(ContentPreviewLayouts.full)

      // all others are only "compact"
    } else if (this.indexColumns.columnNumber === 4) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_offset_left)

      // all others are only "compact"
    } else if (
      this.indexColumns.columnNumber > 4 ||
      this.indexColumns.columnNumber < this.maxColumn
    ) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact)
    }

    // and left/right gutters on some column numbers
    if (
      this.indexColumns.columnNumber === 1 ||
      this.indexColumns.columnNumber === 4
    ) {
      className.push(`right-gutter`)
    }
    if (
      this.indexColumns.columnNumber === 2 ||
      this.indexColumns.columnNumber === 5
    ) {
      className.push(`left-gutter`)
    }

    return className.join(` `)
  }

  listContent = () => {
    if (this.props.data.homeContent.content.length > 0) {
      // will hold content return
      let content = []

      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // loop each content node
      this.props.data.homeContent.content.slice(0, 9).map((item, index) => {
        // set current column
        this.indexColumns.increment()

        let type
        switch (item.__typename) {
          case `ContentfulWork`:
            type = `work`
            break
          // case `ContentfulNews`:
          //   if (item.displayOnWorking) {
          //     type = `culture`
          //   } else {
          //     type = `news`
          //   }
          //   break
        }

        // add all content
        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={item}
            contentType={type}
            layout={this.layout()}
            visible="is-visible"
            effect={
              index < 2 ? `effects__slide_from_left` : `effects__fade_down`
            }
            delay={this.indexColumns.getDelay()}
            duration={index < 2 ? 1250 : 400}
          />
        )

        // insert quote block before third column, only on first column loop
        if (this.indexColumns.columnNumber === 2 && !this.indexColumns.looped) {
          content.push(
            <div key={new Date()} className="landing__blurb_text col-12 mb-5 landing__blurb_text_pad">
              <div className="col-12 col-md-10 col-lg-4 mx-auto pride-text">
              <Link to="/about/office/delhi">
                  <p>Wieden + Kennedy India was founded in 2007 to create culture-shifting work for brands that want to make an impact on India, and the world.</p>
                  <p>We like to tell stories that need to be told, bring alive ideas that need to be seen, and work with people who need to be heard.</p>
                  <nav class="content__preview__nav nav-read-more pride-text text-light"><span class="btn__arrow on-baseline">View more <svg class="icn__arrow__right" width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="Group" transform="translate(0.000000, 1.000000)" stroke="#ffffff"><polyline id="Line-Copy-2" points="6 0 11.2456678 5 6 9.97314045"></polyline><path d="M10.5,5 L0.5,5" id="Line-Copy-2"></path></g></g></svg></span></nav>
                  <StaticImage src="../img/delhi/horse.png" className="delhi_horse" alt="horse" />
              </Link>
              </div>
            </div>
          )
        }
      })

      return content
    }
  }
  
  render() {
    return (
      <Layout>
        <SEO 
        title={`Wieden+Kennedy India - Integrated Advertising Campaigns`} 
        description={`Here's where we post our latest and greatest work. Come see what we've been up to.`} 
        seoFields={this.props.data.seoFields}
        />

        <section className="landing-page">

          {/* top featured content slider */}
          <FeaturedContentSlider
            parent={this}
            featuredContent={this.props.data.featuredContentSlides}
          />

          <div className={`row`}>{this.listContent()}</div>

          
        </section>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return { SiteHeaderState: store.SiteHeaderState }
}

export default connect(
  mapStateToProps,
  {
    invertHeader,
    resetHeader,
  }
)(LandingPage)

export const query = graphql`
  query landing {
    homeBlurbText: contentfulTexts(slug: { eq: "home-blurb-text" }) {
      ...textFragment
    }

    seoFields: contentfulSeoFields(slug: { eq: "home-landing" }) {
      ...seoFieldsFragment
    }

    featuredContentSlides: contentfulCollections(slug: { eq: "home-slides" }) {
      slug
      content {
        ... on ContentfulFeaturedContent {
          ...featuredContentFragment
        }
      }
    }

    homeContent: contentfulCollections(slug: { eq: "home-content" }) {
      slug
      content {
        ... on ContentfulWork {
          ...workFragmentBase
        }
      }
    }

    wiedensims: allContentfulWiedenism {
      edges {
        node {
          id
          wiedensim
        }
      }
    }
  }
`
